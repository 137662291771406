/* eslint-disable no-nested-ternary */
function batchModel(batch) {
  return {
    uid: batch.uid,
    channel: batch.channel,
    name: batch.name,
    slug: batch.slug,
    description: batch.description,
    isEnrolled: batch.is_enrolled,
    timeType: batch.time_type,
    thumbnail: batch.cover_photo,
    state: batch.state,
    startsAt: batch.starts_at,
    completedAt: batch.completed_at,
    preview: batch.intro_video,
    testSeries: batch.test_series_count,
    languages: batch.languages,
    allTopicsCovered: batch.all_topics_covered,
    authors: batch.authors.map((author) => ({ user: author })),
    topicGroups: batch.topic_groups
      ? batch.topic_groups.map((topicGroup) => {
          return { topology: topicGroup };
        })
      : null,
    goal: batch.goal ? { topology: batch.goal } : null,
    quizzesCount: batch.quizzes_count,
    lessonsCount: batch.lessons_count,
    trialClasses: batch.trial_info || {},
    queryID: batch.queryID,
    requestID: batch.requestID,
    index: batch.index,
    contentType: batch.content_type,
    playlistEnabledOnWeb: batch.playlist_enabled_on_web,
    additionalFeatures: batch.additional_features,
    syllabusTag: batch.syllabus_tag
  };
}

function getEntityData(data) {
  return batchModel(data);
}

export const getAuthorName = (author) =>
  author.name || `${author.firstName || ''} ${author.lastName || ''}`;

const getAuthorsName = (authors) => {
  if (!authors) return '';
  const nameText = authors.slice(0, 3).reduce((initial, educator, index) => {
    if (index === 1) return initial + getAuthorName(educator);
    if (index < 2 && authors.length === 1)
      return initial + getAuthorName(educator);
    if (index < 2) return `${initial} ${getAuthorName(educator)}, `;
    return `${initial} and ${authors.length - 2} more`;
  }, '');
  return nameText;
};

export const getLinkPath = (isPartnerEducator) =>
  isPartnerEducator
    ? '/store/batch/[slug]/[batchUID]'
    : '/batch/[slug]/[batchUID]';

export const getLink = (batch, isPartnerEducator) =>
  isPartnerEducator
    ? `/store/batch/${batch.slug}/${batch.uid}`
    : `/batch/${batch.slug}/${batch.uid}`;

const batch = {
  Batch: batchModel,
  getEntityData,
  getAuthorsName,
  getLinkPath,
  getLink
};

export default batch;
