import camelcaseKeys from 'camelcase-keys';

function storeModel(store) {
  return {
    accessEndDate: store.access_end_date,
    contentTypeId: store.content_type_id,
    currency: store.currency,
    maxRetailPrice: store.max_retail_price,
    price: store.price,
    priceEffectiveTill: store.price_effective_till,
    productDetails: camelcaseKeys(store.product_details),
    productId: store.product_id,
    uid: store.uid
  };
}

function getEntityData(data) {
  return storeModel(data);
}

const store = {
  Store: storeModel,
  getEntityData
};

export default store;
