import parseNumber from '@utils/parseNumber';
import { capitalize } from '@utils/textHelper';

export const formatFirstName = (firstName) =>
  firstName ? firstName.toLocaleString() : '';

export const getRelativeLink = (user) => {
  let url = `/learner/@${user.username}`;
  if (user.is_educator || user.is_moderator)
    url = user.relative_link || `/@${user.username}`;
  return url;
};

export const getFullName = (firstName, lastName) =>
  `${capitalize(firstName)} ${capitalize(lastName)}`;

function userModel(userData) {
  const user = userData.author_details ? userData.author_details : userData;
  const firstName = user.first_name ? user.first_name.toLocaleString() : '';
  const lastName = user.last_name ? user.last_name : '';
  return {
    firstName,
    lastName,
    uid: user.uid,
    isFullStoryUser: user.is_full_story_user,
    name: getFullName(firstName, lastName),
    thumbnail: user.avatar_v1 || user.avatar || null,
    description: user.bio || null,
    permalink: user.permalink,
    relativeLink: getRelativeLink(user),
    averageRating: user.avg_rating,

    username: user.username.toLowerCase() || null,
    referralInfo: user.referral_info,
    followersCount: user.followers_count
      ? parseNumber(user.followers_count)
      : null,
    followingCount: user.follows_count ? parseNumber(user.follows_count) : null,
    lifetimeViews: user.lifetime_views
      ? parseNumber(user.lifetime_views)
      : null,
    starRatings: user.star_ratings ? parseNumber(user.star_ratings) : null,
    isFollowing: user.is_following,
    postsCount: user.posts_count ? parseNumber(user.posts_count) : null,
    coursesCount: user.courses_count ? parseNumber(user.courses_count) : null,
    isEducator: user.is_educator,
    isModerator: user.is_moderator,
    isVerifiedEducator: user.is_verified_educator,
    isExEducator: user.is_ex_educator,
    isEmailVerified: user.is_email_verified,
    isPhoneVerified: user.is_phone_verified,
    isProfileFilled: user.is_profile_filled,
    isPlatformEducator: user.is_platform_educator,
    isPlatinumEducator: user.is_platinum_educator,
    dedications: user.dedications,
    topicsDisplay: user.topics_display,
    stats: user.stats,
    phone: user.phone,
    email: user.email,
    state: user.state,
    country: user.country,
    language: user.language,
    canAddPlusCourse: user.can_add_plus_course,
    canAddSpecialClass: user.can_add_special_class,
    verifiedEducatorAt: user.verified_educator_at,
    liveMinutes: user.live_minutes ? Math.round(user.live_minutes / 60) : 0,
    totalWatchMins: user.total_watch_time
      ? Math.round(user.total_watch_time / 60)
      : 0,
    credentials: user.credentials,
    languages: user.languages,
    becomeEducatorAt: user.become_educator_at,
    educatorType: user.educator_type,
    educatorTypeV2: user.educator_type_v2,
    knowsAbout: user.knows_about,
    levelInfo: user.level_info || {},
    levelId: user.level_id,
    dateJoined: user.date_joined,
    ogImage: user.og_image || null,
    leaderboardMetaInfo: user.leaderboard_meta_info || null,
    avatarV1: user.avatar_v1 || null,
    avatarV2: user.avatar_v2 || null,
    oldAvatar: user.old_avatar || null,
    fallbackAvatar: user.avatar || null,
    queryID: user.queryID,
    requestID: user.requestID,
    index: user.index
  };
}

export function getEntityData(data) {
  return userModel(data);
}

export const isLegendEducator = (data = {}) => {
  return data.uid === 'WO7LDJ3Y2Z' || data.educatorType === 4;
};

const user = { User: userModel, getEntityData, isLegendEducator };

export default user;
