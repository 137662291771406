/* eslint-disable camelcase */
function template(courseTemplate = {}) {
  const templateData = {
    uid: courseTemplate.id,
    sessionDuration: courseTemplate.session_duration,
    totalLessons: courseTemplate.total_lessons,
    title: courseTemplate.title,
    description: courseTemplate.description,
    quizzes: courseTemplate?.quizzes || [],
    goal: courseTemplate.goal,
    topics: courseTemplate.topics || [],
    liveClasses: courseTemplate.live_classes.map((liveClass) => {
      const data = { ...liveClass };
      if (liveClass.practice_quiz?.uid) data.hasPracticeQuiz = true;
      return data;
    }),
    courseType: courseTemplate.course_type,
    state: courseTemplate.state,
    exams: courseTemplate?.exams || [],
    totalQuizzes: courseTemplate.total_quizzes,
    totalDuration: courseTemplate.total_duration,
    topicGroups: courseTemplate.topic_groups,
    createdAt: courseTemplate.created_at,
    selectedClasses: [],
    isContentOperator: courseTemplate.is_content_operator,
    driveDetails: courseTemplate.drive_details,
    sessionsInfo: courseTemplate.quiz_info,
    lessonsDuration: courseTemplate.lessons_duration
  };

  templateData.goal.name = courseTemplate.goal.goal_name;
  templateData.goal.uid = courseTemplate.goal.goal_uid;

  if (courseTemplate.class_preference_uids) {
    templateData.selectedClasses = courseTemplate.class_preference_uids;
  }

  return templateData;
}

function getEntityData(data) {
  return template(data);
}

const courseTemplate = {
  Template: template,
  getEntityData
};

export default courseTemplate;
