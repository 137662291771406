function doubtModel(doubt) {
  return {
    doubtUID: doubt.uid,
    question: doubt.question,
    isActive: doubt.is_active,
    description: doubt.description,
    state: doubt.state,
    difficulty: doubt.difficulty_level,
    createdAt: doubt.created_at,
    updatedAt: doubt.updated_at,
    goal: { topology: doubt.goal },
    user: { user: doubt.user },
    learnerPrefLanguage: doubt.learner_language_preference,
    topicGroup: doubt.topic_group ? { topology: doubt.topic_group } : null,
    classInfo: doubt?.class_info,
    topologies: doubt.topologies
      ? doubt.topologies.map((topology) => topology)
      : null,
    assignedAt: doubt?.assigned_at,
    unassignTimerDuration: doubt?.unassign_timer_duration,
    recordingStartedAt: doubt?.recording_started_at,
    unassignRecordingTimerDuration: doubt?.unassign_recording_timer_duration
  };
}

function getEntityData(data) {
  return doubtModel(data);
}

const doubt = {
  Doubt: doubtModel,
  getEntityData
};

export default doubt;
