function doubtSolutionModel(doubtSolution) {
  return {
    solutionUid: doubtSolution.uid,
    state: doubtSolution.state,
    language: doubtSolution.language,
    createdAt: doubtSolution.created_at,
    learnersReport: doubtSolution.learners_report,
    video: doubtSolution.video,
    isReviewed: doubtSolution?.is_reviewed,
    likePercentage: doubtSolution.rating?.likes_percent,
    totalRatings: doubtSolution.rating?.total,
    user: doubtSolution.user,
    doubt: { doubt: doubtSolution.doubt },
    status: doubtSolution.solution_status,
    reviewUnassignRecorTimerDuration:
      doubtSolution?.unassign_recording_timer_duration,
    reviewRecordingStartedAt: doubtSolution?.recording_started_at
  };
}

function getEntityData(data) {
  return doubtSolutionModel(data);
}

const doubtSolution = {
  DoubtSolution: doubtSolutionModel,
  getEntityData
};

export default doubtSolution;
