/* eslint-disable max-lines */
import { schema } from 'normalizr';
import paginateAction from '@utils/paginateAction';
import { HOST_TYPE } from '@utils/api-helper/host';
import ME from '@models/Me';
import config from '@api/fetchers/config';
import { authTypes, subscriptionTypes } from '../constants';
import { meSchema, topologySchema, paginatorListSchema } from '../schemas';

export const authenticate = ({ accessToken, refreshToken }) => ({
  type: authTypes.AUTHENTICATE,
  payload: { accessToken, refreshToken }
});

export const reauthenticate = ({ accessToken, refreshToken }) => ({
  type: authTypes.AUTHENTICATE,
  payload: { accessToken, refreshToken }
});

export const setSelectedGoal = (selectedGoal) => ({
  type: authTypes.SELECTED_GOAL,
  selectedGoal
});

export const showFollowGoalTooltip = (show) => ({
  type: authTypes.SHOW_FOLLOW_GOAL_TOOLTIP,
  show
});

export const showSwitchGoalTooltip = (show) => ({
  type: authTypes.SHOW_SWITCH_GOAL_TOOLTIP,
  show
});

export const setLocalPhoneNumber = (phone, country) => ({
  type: authTypes.SET_LOCAL_PHONE,
  phone,
  country
});

export const setLocalEmailAndName = (email, name) => ({
  type: authTypes.SET_LOCAL_EMAIL_NAME,
  email,
  name
});

export const deauthenticate = () => ({ type: authTypes.DEAUTHENTICATE });

export const fetchMyInfo =
  (source = '') =>
  (dispatch) => {
    const actionPayload = {
      type: authTypes.ME,
      endpoint: 'v2/user/me/',
      method: 'get',
      schema: meSchema,
      headers: {}
    };
    if (source)
      actionPayload.headers = {
        ...actionPayload.headers,
        'X-Source': source
      };

    return dispatch(actionPayload);
  };

export const fetchFollowedGoals = () => (dispatch) =>
  dispatch({
    type: authTypes.FOLLOWED_GOALS,
    endpoint:
      'v1/user/me/pinned_topology/?subscribed_goals=true&include_icons=true',
    hostType: HOST_TYPE.BACKEND_API_HOST,
    method: 'get',
    schema: [{ topology: topologySchema }],
    entityType: 'topology',
    processData: (data) =>
      data.map((resultItem) => ({
        ...resultItem,
        slug: resultItem.relative_link?.split('/')[2]
      }))
  });

export const fetchCountryStates =
  (countryCode = 'IN') =>
  (dispatch) =>
    dispatch({
      type: authTypes.FETCH_COUNTRY_STATES,
      endpoint: `v1/internationalization/states/?country_code=${countryCode}`,
      method: 'get'
    });

export const fetchStates = () => (dispatch) =>
  dispatch({
    type: authTypes.FETCH_STATES,
    endpoint: 'api/states',
    method: 'get',
    hostType: HOST_TYPE.CMS
  });

export const fetchCredentials = () => (dispatch) =>
  dispatch({
    type: authTypes.FETCH_CREDENTIALS,
    endpoint: 'v1/user/me/credentials',
    method: 'get'
  });

export const fetchBioRecommendation = () => (dispatch) =>
  dispatch({
    type: authTypes.FETCH_BIO_RECOMMENDATION,
    endpoint: 'v1/user/me/bio_recommendation',
    method: 'get'
  });

export const resetOtpResponse = () => ({
  type: authTypes.RESET_OTP_RESPONSE
});

export const createAccountOutsideIndia =
  ({
    email = '',
    backend,
    countryCode = '',
    firstName = '',
    lastName = '',
    phone = '',
    promotionalOk = '',
    pwd,
    sendOtp = true,
    tnc_consent,
    age_verification
  }) =>
  (dispatch) =>
    dispatch({
      endpoint: 'v1/user/signup/',
      method: 'post',
      data: {
        client_id: config.apiClientId,
        client_secret: config.apiClientSecret,
        password: pwd,
        email,
        backend,
        country_code: countryCode, // always geo location
        first_name: firstName,
        send_otp: sendOtp,
        last_name: lastName,
        phone,
        promotional_ok: promotionalOk,
        tnc_consent,
        age_verification
      }
    });

export const sendOtpEmailSignup =
  ({ signupToken, email }) =>
  (dispatch) =>
    dispatch({
      endpoint: 'v1/user/send-signup-otp/',
      method: 'post',
      data: {
        email,
        signup_token: signupToken
      }
    });

export const verifyEmailSignupOtp =
  ({ signupToken, email, otp }) =>
  (dispatch) =>
    dispatch({
      endpoint: 'v1/user/verify-signup-otp/',
      method: 'post',
      data: {
        email,
        signup_token: signupToken,
        otp
      }
    });

export const updateSignupInfo =
  ({
    signupToken = '',
    firstName = '',
    lastName = '',
    phone = false,
    promotionalOk,
    countryCode = '',
    email = ''
  }) =>
  (dispatch) =>
    dispatch({
      endpoint: 'v1/user/update-signup-info/',
      method: 'post',
      data: {
        signup_token: signupToken,
        email,
        first_name: firstName,
        last_name: lastName,
        phone,
        country_code: countryCode,
        promotional_ok: promotionalOk
      }
    });

export const validateEmailPhone =
  ({
    email = '',
    countryCode = 'IN',
    phone = '',
    sendOtp = false,
    isEmailLoginFlow = false
  }) =>
  (dispatch) =>
    dispatch({
      endpoint: 'v3/user/user_check/',
      method: 'post',
      data: {
        email,
        phone,
        country_code: countryCode, // geolocation if email or dropdown code if phone
        send_otp: isEmailLoginFlow ? true : sendOtp
      }
    });

export const verifyTTUNNumber =
  ({ phone, otp, countryCode }) =>
  (dispatch) =>
    dispatch({
      endpoint: 'v3/user/ttun_verify_phone/',
      method: 'post',
      data: {
        phone,
        otp,
        country_code: countryCode
      }
    });

export const ttunUpdatePhone =
  ({ phone, otp, countryCode, phoneCode }) =>
  (dispatch) =>
    dispatch({
      endpoint: 'v3/user/ttun_update_phone/',
      method: 'post',
      data: {
        phone,
        country_code: countryCode,
        phone_code: phoneCode,
        otp
      }
    });

export const updatePassword =
  ({ currentPassword = '', newPassword = '' }) =>
  (dispatch) =>
    dispatch({
      endpoint: 'v1/user/update-password/',
      method: 'post',
      data: {
        current_password: currentPassword,
        new_password: newPassword
      }
    });

export const resetPassword =
  ({ token = '', newPassword = '' }) =>
  (dispatch) =>
    dispatch({
      endpoint: 'v1/user/reset-password/',
      method: 'post',
      data: {
        token,
        password: newPassword
      }
    });

export const forgotPassword =
  ({ email = '' }) =>
  (dispatch) =>
    dispatch({
      endpoint: 'v1/user/forgot-password/',
      method: 'post',
      data: {
        email
      }
    });

// created this new login which accepts obj, so order of arguments doesn't matter and also easy to pass default arguments.
// TODO: replace login with this later
export const loginv2 =
  ({
    email = '',
    backend,
    countryCode = '',
    accessToken = '',
    firstName = '',
    lastName = '',
    phone = '',
    register = '',
    pwd = '',
    otp = '',
    signupToken = '',
    signup_tnc_pp = '',
    signup_age_13 = '',
    signup_promo_calls = ''
  }) =>
  (dispatch) =>
    dispatch({
      endpoint: 'v1/user/login/',
      method: 'post',
      data: {
        client_id: config.apiClientId,
        client_secret: config.apiClientSecret,
        otp,
        email,
        backend,
        password: pwd,
        country_code: countryCode, // always geo location
        access_token: accessToken,
        first_name: firstName,
        last_name: lastName,
        signup_tnc_pp,
        signup_age_13,
        signup_promo_calls,
        phone,
        register,
        signup_token: signupToken
      }
    });

export const sendOtpOrEmail =
  (phone, countryCode, otpType, email = '', for_classroom = false) =>
  (dispatch) =>
    dispatch({
      type: authTypes.RESEND_OTP,
      endpoint: 'v3/user/user_check/?enable-email=true',
      method: 'post',
      data: {
        phone,
        country_code: countryCode,
        otp_type: otpType,
        email,
        send_otp: true,
        is_un_teach_user: for_classroom
      }
    });

export const latestStreakDetail = () => (dispatch) =>
  dispatch({
    type: authTypes.LATEST_STREAK,
    endpoint: 'v1/gamification/latest_streak/detail/',
    method: 'get'
  });

export const login =
  (
    email,
    phone,
    otp,
    countryCode,
    backend,
    firstName,
    lastName,
    state,
    password,
    register,
    for_classroom = false,
    code = '',
    ageGroupID = '',
    accessToken
  ) =>
  (dispatch) =>
    dispatch({
      type: authTypes.LOGIN,
      endpoint: 'v1/user/login/?enable-email=true',
      method: 'post',
      data: {
        backend,
        phone,
        otp,
        access_token: accessToken,
        client_id: config.apiClientId,
        client_secret: config.apiClientSecret,
        country_code: countryCode,
        email,
        first_name: firstName,
        last_name: lastName,
        state,
        password,
        register,
        user_info: false,
        is_un_teach_user: for_classroom,
        un_teach_institue_code: code,
        country_age_group_uid: ageGroupID
      }
    });

export const updateUser = (payload) => (dispatch) =>
  dispatch({
    type: authTypes.UPDATE_USER,
    endpoint: 'v1/user/update/?v=2',
    method: 'post',
    data: payload
  });

export const updateCredentials = (payload) => (dispatch) =>
  dispatch({
    type: authTypes.UPDATE_CREDENTIALS,
    endpoint: 'v1/user/me/credentials/',
    method: 'post',
    data: payload
  });

export const unlockFreePlan = (payload) => ({
  type: authTypes.UNLOCK_FREE_PLAN,
  endpoint: 'v1/uplus/platform/unlock/',
  method: 'post',
  data: payload
});

export const resetNetworkCallsError = () => ({
  type: authTypes.RESET_NETWORK_CALLS_ERROR
});

// creating a new one as its better to have function accepting named params.
// TODO: replace sendMailOtp with sendMailOtpv2
export const sendMailOtpv2 =
  ({ email = '', countryCode = '', password = '' }) =>
  (dispatch) =>
    dispatch({
      type: authTypes.SEND_MAIL_OTP,
      endpoint: 'v1/user/send_mail_otp/',
      method: 'post',
      data: { email, password, country_code: countryCode } // always geo location
    });

export const sendMailOtp = (email) => (dispatch) =>
  dispatch({
    type: authTypes.SEND_MAIL_OTP,
    endpoint: 'v1/user/send_mail_otp/',
    method: 'post',
    data: { email }
  });

export const verifyMailOtp =
  (email, otp, country_code = '', password = '') =>
  (dispatch) =>
    dispatch({
      type: authTypes.VERIFY_MAIL_OTP,
      endpoint: 'v1/user/verify_mail_otp/',
      method: 'post',
      data: { email, otp, country_code, password } // always geo location
    });

export const sendMobileOtp = (phone, countryCode) => (dispatch) =>
  dispatch({
    type: authTypes.SEND_MOBILE_OTP,
    endpoint: 'v1/user/me/verify_phone_otp/',
    method: 'post',
    data: { phone, country_code: countryCode, otp_type: 1 }
  });

export const verifyMobileOtp =
  (phone, otp, signup_promo_calls = '') =>
  (dispatch) =>
    dispatch({
      type: authTypes.VERIFY_MOBILE_OTP,
      endpoint: 'v1/user/me/verify_phone/',
      method: 'post',
      data: { phone, otp, signup_promo_calls }
    });

export const fetchSubscriptionFeedbackCards = (goalUID) => (dispatch) =>
  dispatch({
    type: subscriptionTypes.FETCH_SUBSCRIPTION_FEEBACK_CARDS,
    endpoint: `v1/feedback/card/?object_uid=${goalUID}&feedback_type=gtp_subscription`,
    method: 'get'
  });

export const fetchFeedbackOptions = (objectUID, feedbackType) => (dispatch) =>
  dispatch({
    type: authTypes.FETCH_FEEDBACK_OPTIONS,
    endpoint: `v1/feedback/${feedbackType}/?object_uid=${objectUID}`,
    method: 'get',
    schema: new schema.Object({})
  });

export const submitSubscriptionFeedback = (data, uid, extraData) => ({
  type: authTypes.SUBMIT_FEEDBACK,
  endpoint: `v1/feedback/${uid}/response/`,
  method: 'post',
  data,
  ...extraData
});

export const updateAvatar = (newAvatar) => (dispatch) =>
  dispatch({
    type: authTypes.UPDATE_AVATAR,
    endpoint: 'v1/user/update-avatar/?v=2',
    method: 'put',
    data: { avatar: newAvatar }
  });

export const fetchRenewalTouchPoint = (goalUID) => (dispatch) =>
  dispatch({
    type: authTypes.FETCH_RENEWAL_TOUCHPOINT_DATA,
    endpoint: `v1/subscriptions/suggested_renew_referral_code/?goal_uid=${goalUID}`,
    method: 'get',
    goalUID,
    schema: new schema.Object({}),
    processData: (data) => ({
      percentage: data.percentage,
      code: data.code,
      show: data.show,
      maxDiscount: data.max_discount,
      validTill: data.valid_till,
      daysRemaining: data.days_remaining
    })
  });

export const fetchSettingsManageUpdates = () => (dispatch) =>
  dispatch({
    type: authTypes.FETCH_SETTINGS_MANAGE_UPDATES,
    endpoint: 'v1/move/preferences',
    method: 'get',
    schema: new schema.Object({}),
    processData: (data) => {
      const newData = {};
      data.forEach((resultItem) => {
        newData[resultItem.meta.key] = resultItem;
      });

      return newData;
    }
  });

export const fetchReferralDetails = () => (dispatch) =>
  dispatch({
    type: authTypes.FETCH_REFERRAL_DETAILS,
    endpoint: 'v2/subscriptions/referrals/details/',
    method: 'get',
    hostType: HOST_TYPE.BACKEND_API_HOST,
    schema: new schema.Object({})
  });

const getReferralsAction = () => (next) => ({
  type: authTypes.FETCH_REFERRAL_LIST,
  endpoint: next,
  method: 'get',
  key: 'referrals',
  schema: paginatorListSchema
});

export const fetchReferrals = (nextPage, resetWithoutEmpty = false) =>
  paginateAction(
    'v1/subscriptions/referrals/?limit=30',
    getReferralsAction(),
    'referralList',
    'referrals',
    resetWithoutEmpty
  )(nextPage);

export const changeManageUpdatePreferencesV2 = (blockType, key, flag) => ({
  type: authTypes.CHANGE_MANAGE_UPDATE_PREFERENCES,
  endpoint: 'v1/move/preferences',
  method: 'post',
  data: {
    type: blockType,
    channel: key,
    is_enabled: flag
  },
  blockType,
  key,
  flag
});

export const setPrePaymentSubscriptionUids = (uids) => ({
  type: authTypes.PRE_PAYMENT_PLUS_SUBSCRIPTION_UIDS,
  uids
});

export const createCustomCode = (goalUID, suffix) => ({
  type: authTypes.GENERATE_CUSTOM_CODE,
  endpoint: 'v1/subscriptions/referral_code/create_variable/',
  method: 'post',
  data: {
    goal_uid: goalUID,
    campaign_display_name: suffix
  }
});

export const getCustomCodeData = (suffix) => ({
  type: authTypes.GET_CUSTOM_CODE_DATA,
  endpoint: `v1/subscriptions/referral_code/custom_code_details/?campaign_display_name=${suffix}`,
  method: 'get'
});

export const fetchPurchasedItems = () => (dispatch) =>
  dispatch({
    type: authTypes.FETCH_PURCHASED_ITEMS,
    endpoint: 'v1/user/purchased_items/',
    method: 'get',
    hostType: HOST_TYPE.BACKEND_API_HOST,
    schema: new schema.Object({}),
    processData: (data) => {
      const platformSubscriptionUIDs = ME.getPlatformSubscriptionUIDs(
        data.user_subscriptions
      );
      const platformSubscriptionGoalUIDs =
        data?.user_subscriptions
          ?.map((subscription) =>
            platformSubscriptionUIDs.includes(subscription.uid)
              ? subscription.goal.uid
              : null
          )
          .filter((subscriptionGoalUID) => subscriptionGoalUID) || [];
      const newData = {
        hasActiveBatch: platformSubscriptionUIDs?.length > 0,
        batchGroupSubscriptions: data.user_subscriptions,
        platformSubscriptionUIDs,
        platformSubscriptionGoalUIDs
      };

      return newData;
    }
  });
