import { itemTypes, bannerStates } from '@constants/index';
import {
  isTopicFeed,
  isCourseFeedBlock,
  isEducatorBlock,
  isBatchBlock,
  isBannerBlock,
  isBatchGroupBlock,
  isContentStatsFeed
} from '@utils/feedTypeHelper';

const getUid = (data) => {
  let uid = data.type || data.block_type;
  if (data.key) uid += `_${data.key}`;
  if (data.extra_block_info) {
    if (data.extra_block_info.id) uid += `_${data.extra_block_info.id}`;
    if (data.extra_block_info.uid) uid += `_${data.extra_block_info.uid}`;
    if (data.extra_block_info.title)
      uid += `_${data.extra_block_info.title.split(' ').join('_')}`;
  }
  return uid;
};

function feedrowModel(feedrow) {
  const feedrowData = {
    key: feedrow.key || '',
    type: feedrow.type || feedrow.block_type,
    extraBlockinfo: feedrow.extra_block_info,
    coursesCount: feedrow.courses_count,
    uid: getUid(feedrow),
    label:
      feedrow.label ||
      feedrow.title ||
      feedrow.extra_block_info?.label ||
      feedrow.extra_block_info?.title ||
      feedrow.extra_block_info?.name ||
      feedrow.extra_block_info?.entity_type,
    data: feedrow.data
  };
  if (feedrowData.coursesCount)
    feedrowData.extraBlockinfo.coursesCount = feedrowData.coursesCount;

  const id = feedrow.extra_block_info
    ? feedrow.extra_block_info.id?.toLowerCase()
    : '';

  if (isContentStatsFeed(feedrow.type)) feedrowData.data = feedrow.data;
  else if (isCourseFeedBlock(feedrow.extra_block_info.entity_type)) {
    feedrowData.courses = feedrow.data
      .map((courseData) => {
        if (courseData.type === itemTypes.POST)
          return { item: courseData.data };
        if (!courseData.batch_template)
          return { course: courseData.data || courseData };
        return null;
      })
      .filter((obj) => obj);
    feedrowData.batches = feedrow.data
      .map((batchData) =>
        batchData.batch_template ? { batch: batchData } : null
      )
      .filter((obj) => obj);
  } else if (isEducatorBlock(feedrow.extra_block_info.entity_type))
    feedrowData.users = feedrow.data.map((educatorData) => ({
      user: educatorData
    }));
  else if (isTopicFeed(id))
    feedrowData.topologies = feedrow.data.map((topologyData) => ({
      topology: topologyData
    }));
  else if (feedrowData.type === 'dym')
    feedrowData.suggestions = feedrow.data.map(
      (suggestion) => suggestion.suggestion
    );
  else if (feedrowData.type === 'related_searches')
    feedrowData.relatedSearches = feedrow.data.map((query) => query.query);
  else if (isBatchBlock(feedrow.extra_block_info.entity_type)) {
    feedrowData.batches = feedrow.data.map((batchData) => ({
      batch: batchData
    }));
  } else if (isBatchGroupBlock(feedrow.extra_block_info.entity_type)) {
    feedrowData.batchgroups = feedrow.data.map((batchData) => ({
      batchGroup: batchData
    }));
  } else if (isBannerBlock(feedrowData.type))
    feedrowData.carouselFeed = feedrow.data.map((data) => {
      const bannerDetails = {
        uid: data.uid,
        title: data.title,
        description: data.description,
        type: data.banner_type,
        bannerPhoto: data.banner_photo,
        clickURL: data.click_url
      };
      if (data.banner_type === bannerStates.ITEM)
        return {
          course: data.session_info.course_info,
          sessionData: data.session_info,
          bannerDetails
        };
      if (
        [
          bannerStates.LEGENDS,
          bannerStates.TEST_SERIES,
          bannerStates.GENERIC,
          bannerStates.COMBAT,
          bannerStates.RENEWAL
        ].includes(data.banner_type)
      )
        return {
          course: data.session_info,
          sessionData: data.session_info,
          bannerDetails
        };
      return data;
    });
  else if (feedrow.type === 'combat') {
    feedrowData.combatData = feedrowData.data.map((combat) => {
      return {
        combat
      };
    });
  }
  if (feedrow.topology) feedrowData.topology = feedrow.topology;
  return feedrowData;
}

function getEntityData(data) {
  return feedrowModel(data);
}

const feedrow = { Feedrow: feedrowModel, getEntityData, getUid };

export default feedrow;
