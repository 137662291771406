import { schema } from 'normalizr';
import mergeDeep from '@utils/mergeDeep';
import Feedrow from '@models/Feedrow';
import { getEntityData } from '@models/User';
import { getEntityData as getTopologyEntityData } from '@models/Topology';
import Course from '@models/Course';
import Item from '@models/Item';
import Batch from '@models/Batch';
import BatchGroup from '@models/BatchGroup';
import Me from '@models/Me';
import Doubt from '@models/Doubt';
import DoubtSolution from '@models/DoubtSolution';
import Template from '@models/CourseTemplate';
import Combat from '@models/Combat';
import Store from '@models/Store';

export const userSchema = new schema.Entity(
  'users',
  {},
  {
    idAttribute: (value) =>
      value.author_details
        ? value.author_details.username?.toLowerCase()
        : value.username?.toLowerCase(),
    processStrategy: (value) => getEntityData(value),
    mergeStrategy: (a, b) => mergeDeep(a, b)
  }
);

export const topologySchema = new schema.Entity(
  'topologies',
  {},
  {
    idAttribute: (value) => value.uid,
    processStrategy: (value) => getTopologyEntityData(value),
    mergeStrategy: (a, b) => mergeDeep(a, b)
  }
);

export const topologyListsSchema = new schema.Object({
  results: [new schema.Object({ topology: topologySchema })]
});

export const courseDummySchema = new schema.Entity(
  'courses',
  {
    authors: [{ user: userSchema }],
    goal: { topology: topologySchema },
    topicGroups: [{ topology: topologySchema }]
  },
  {
    idAttribute: (value) => {
      return value.object ? value.object.uid : value.uid;
    },
    processStrategy: (value) =>
      Course.getEntityData(value.object ? value.object : value),
    mergeStrategy: (a, b) => mergeDeep(a, b)
  }
);

export const itemSchema = new schema.Entity(
  'items',
  {
    author: userSchema,
    programme: { course: courseDummySchema },
    topicGroups: [{ topology: topologySchema }],
    goal: { topology: topologySchema },
    topology: { topology: topologySchema }
  },
  {
    idAttribute: (value) => {
      return value.object
        ? value.object.uid
        : value.uid || value.properties.uid;
    },
    processStrategy: (value) =>
      Item.getEntityData(value.object ? value.object : value),
    mergeStrategy: (a, b) => mergeDeep(a, b)
  }
);

export const courseSchema = new schema.Entity(
  'courses',
  {
    authors: [{ user: userSchema }],
    nextTrack: { item: itemSchema },
    previousTrack: { item: itemSchema },
    goal: { topology: topologySchema },
    topicGroups: [{ topology: topologySchema }],
    topologies: [{ topology: topologySchema }],
    liveClasses: [{ item: itemSchema }]
  },
  {
    idAttribute: (value) => {
      return value.object ? value.object.uid : value.uid;
    },
    processStrategy: (value) =>
      Course.getEntityData(value.object ? value.object : value),
    mergeStrategy: (a, b) => mergeDeep(a, b)
  }
);

export const batchSchema = new schema.Entity(
  'batches',
  {
    authors: [{ user: userSchema }],
    goal: { topology: topologySchema },
    topicGroups: [{ topology: topologySchema }]
  },
  {
    idAttribute: (value) => value.uid,
    processStrategy: (value) => Batch.getEntityData(value),
    mergeStrategy: (a, b) => mergeDeep(a, b)
  }
);
export const batchGroupSchema = new schema.Entity(
  'batchGroups',
  {
    authors: [{ user: userSchema }],
    goal: { topology: topologySchema },
    topicGroups: [{ topology: topologySchema }]
  },
  {
    idAttribute: (value) => value.uid,
    processStrategy: (value) => BatchGroup.getEntityData(value),
    mergeStrategy: (a, b) => mergeDeep(a, b)
  }
);

export const combatSchema = new schema.Entity(
  'combat',
  { user: userSchema, author: userSchema },
  {
    idAttribute: 'uid',
    processStrategy: (value) => Combat.getEntityData(value),
    mergeStrategy: (a, b) => mergeDeep(a, b)
  }
);

export const storeSchema = new schema.Entity(
  'store',
  {},
  {
    idAttribute: (value) => {
      return value.object
        ? value.object.uid
        : value.uid || value.properties.uid;
    },
    processStrategy: (value) => {
      return Store.getEntityData(value);
    },
    mergeStrategy: (a, b) => mergeDeep(a, b)
  }
);

export const feedRowSchema = new schema.Entity(
  'feedrows',
  {
    courses: [{ course: courseSchema, item: itemSchema }],
    users: [{ user: userSchema }],
    topologies: [{ topology: topologySchema }],
    batches: [{ batch: batchSchema }],
    batchgroups: [{ batchGroup: batchGroupSchema }],
    topology: topologySchema,
    carouselFeed: [
      { batch: batchSchema, course: courseSchema, item: itemSchema }
    ],
    combatData: [{ combat: combatSchema }]
  },
  {
    idAttribute: (value) => Feedrow.getUid(value),
    processStrategy: (value) => Feedrow.getEntityData(value),
    mergeStrategy: (a, b) => mergeDeep(a, b)
  }
);

export const questionSchema = new schema.Entity(
  'questions',
  {},
  {
    idAttribute: 'uid',
    mergeStrategy: (a, b) => mergeDeep(a, b)
  }
);

export const doubtSchema = new schema.Entity(
  'doubts',
  {
    goal: { topology: topologySchema },
    user: { user: userSchema },
    topicGroup: { topology: topologySchema },
    topologies: [{ topology: topologySchema }]
  },
  {
    idAttribute: 'uid',
    processStrategy: (value) => Doubt.getEntityData(value),
    mergeStrategy: (a, b) => mergeDeep(a, b)
  }
);

export const doubtSolutionSchema = new schema.Entity(
  'doubtSolutions',
  { doubt: { doubt: doubtSchema } },
  {
    idAttribute: 'uid',
    processStrategy: (value) => DoubtSolution.getEntityData(value),
    mergeStrategy: (a, b) => mergeDeep(a, b)
  }
);

export const templateSchema = new schema.Entity(
  'templates',
  {},
  {
    idAttribute: 'id',
    processStrategy: (value) => Template.getEntityData(value),
    mergeStrategy: (a, b) => mergeDeep(a, b)
  }
);

// paginator related schemas from here
const schemaDictionary = {
  user: userSchema,
  course: courseSchema,
  feedrow: feedRowSchema,
  item: itemSchema,
  topology: topologySchema,
  batch: batchSchema,
  question: questionSchema,
  doubt: doubtSchema,
  doubtSolution: doubtSolutionSchema,
  template: templateSchema,
  combat: combatSchema,
  batchGroup: batchGroupSchema,
  store: storeSchema
};
const paginatorItemSchema = new schema.Object(schemaDictionary);

export const paginatorResultsSchema = new schema.Array(paginatorItemSchema);

export const paginatorListSchema = new schema.Object(
  {
    results: paginatorResultsSchema
  },
  (value, parent, key) => key
);

export const meSchema = new schema.Entity(
  'me',
  {},
  {
    idAttribute: (value) => value.uid,
    processStrategy: (value) => Me.getEntityData(value),
    mergeStrategy: (a, b) => mergeDeep(a, b)
  }
);
