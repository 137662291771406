/* eslint-disable no-lonely-if */
import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';
import isObject from './isObject';

const mergeDeep = (target, source, level = 1, count = 0) => {
  let output = cloneDeep(target);
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (source[key]) {
        if (isObject(source[key])) {
          if (
            !(key in target) ||
            target[key] === undefined ||
            target[key] === null
          )
            output = merge(output, { [key]: source[key] });
          else if (level > count)
            output[key] = mergeDeep(target[key], source[key], level, count + 1);
        } else {
          if (key === 'state')
            output = merge(output, { [key]: target[key] ?? source[key] });
          else output = merge(output, { [key]: target[key] || source[key] });
        }
      } else if (
        ['lessonsCompleted', 'window'].includes(key) &&
        source[key] === 0
      ) {
        output = merge(output, { [key]: target[key] ?? source[key] });
      }
    });
  }
  return output;
};

export default mergeDeep;
