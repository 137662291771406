import { getStringWordsJoinedWith } from '@utils/textHelper';
import { topologyLevels } from '@constants/index';

export function topologyModal(topology) {
  // window map
  // "today": 0,
  // "tomorrow": 1,
  // "this week": 2,
  // "in 15 days": 3,
  // "yesterday": -1,
  // "last week": -2,
  // "15 days ago": -3,
  return {
    uid: topology.uid,
    name: topology.name,
    permalink: topology.permalink,
    relativeLink: topology.relative_link,
    goalSlug: topology.slug,
    title: topology.title,
    coursesCount:
      topology.courses_count ||
      topology.item_count ||
      topology.count ||
      topology.plus_course_count ||
      topology.course_count ||
      0,
    upcomingCoursesCount: topology.upcoming_count || 0,
    upcomingCoursesThisMonth: topology.upcoming_this_month || 0,
    enrolledCoursesCount: topology.enrolled_count || 0,
    totalSubItem: topology.total_subItem || 0,
    progress: topology.progress || 0,
    color: topology.color,
    plusCourseCount: topology.plusCourseCount || topology.total_count || 0,
    colorCode: topology.color_code,
    level: topology.level,
    fullName: topology.title,
    icon: topology.icon,
    children: topology.children,
    slug: topology.slug || getStringWordsJoinedWith(topology.name, '-'),
    parent: topology.parent,
    isPracticeAvailable: topology.is_practice_session,
    isPlusAvailable: topology.is_plus_available,
    isFreeUnavailable: topology.is_free_unavailable,
    language: topology.language || null,
    ogImage: topology.og_image || null,
    window: topology.window,
    windowCount: topology.window_count,
    selfDataFetched: topology.selfDataFetched || false,
    tags: topology.tags,
    isNoTestGoal: !!topology.tags?.find((tag) => tag?.name === 'NON_TEST_PREP'),
    isIconicAvailable: topology.is_iconic_available,
    externalApps: topology.external_apps_available,
    isSyllabusV1Enabled: topology.is_syllabus_v1_enabled,
    isPlatformGoal: topology.is_platform_goal || false,
    goalLevelOnboarding: {
      isAvailable: topology.is_goal_onboarding_available || false,
      status: topology.user_onboarding_status || null,
      hideNudge: false
    },
    isLeaderdboardMetaInfoAvailable:
      topology.is_leaderdboard_meta_info_available,
    leaderboardFilters: topology.leaderboard_filters,
    leaderboardScreenMetaInfo: topology.leaderboard_screen_meta_info,
    isCatalogueFeatureAvailable:
      topology.is_catalogue_feature_available || false,
    isK12: topology.is_k12_goal || false,
    isUaIconGoal: topology.is_ua_icon_goal || false,
    isRecordedGoal: topology.is_recorded_goal,
    isCentreAvailable: topology.is_centre_available
  };
}

export function getEntityData(data) {
  return topologyModal(data);
}

export const getLink = (data) => {
  return `/goal/${data?.slug}/${data?.uid}`;
};

export const getBrowseLinkAs = (data) =>
  `/goal/${data.slug}/${data.uid}/browse`;
export const getBrowseLinkHref = () => '/goal/[goalSlug]/[goalUID]/browse';
export const getBrowseLink = (data) => ({
  as: getBrowseLinkAs(data),
  href: getBrowseLinkHref()
});
export const getPlannerLinkAs = (data) =>
  `/goal/${data.slug}/${data.uid}/planner`;
export const plannerLink = '/goal/[goalSlug]/[goalUID]/planner';
export const getPannerLink = (data) => ({
  as: getPlannerLinkAs(data),
  href: plannerLink
});
/**
 * Retuns link href of a generic goal
 * @returns string pattern of a generic goal href
 */
export const getLinkHref = () => '/goal/[goalSlug]/[goalUID]';

export const getTopicLink = (topic, goal) =>
  `/goal/${goal.slug}/${goal.uid}/topic/${topic.uid}`;

export const getTopicLinkHref = () =>
  '/goal/[goalSlug]/[goalUID]/topic/[topicUid]';

export const getTopicListLink = (goal) =>
  `/goal/${goal.slug}/${goal.uid}/topics`;

export const getTopicListHref = () => '/goal/goalSlug/goalUID/topics';

export const getLinkPath = () => '/goal/[goalSlug]/[goalUID]/topic/[topicUid]';

export const getSpecialClassesLink = (data) => `${getLink(data)}/classes`;

export const getSpecialClassesLinkHref = () => `${getLinkHref()}/classes`;

export const getSpecialClassesAllLink = (data) => ({
  href: '/goal/[goalSlug]/[goalUID]/classes',
  as: `${getLink(data)}/classes`
});

export const getGtpFreeClassesAllLink = (data) => ({
  href: '/goal/[goalSlug]/[goalUID]/classes/[type]',
  as: `${getLink(data)}/classes/all_classes`
});

export const getYoutubeCourseLink = (data) => ({
  href: '/content/p/freecourses/[goalUID]',
  as: `/content/p/freecourses/${data.goalUID}`
});

export const getYoutubeLessonLink = (data) => ({
  href: '/content/p/freecourses/[goalUID]/[courseTitle]/[courseUID]/[lessonUID]',
  as: `/content/p/freecourses/${data.goalUID}/${data.courseTitle}/${data.courseUID}/${data.lessonUID}`
});

export const getHrefAndAsOfAFreeCourse = (data) => ({
  href: '/goal/[goalSlug]/[goalUID]/free-platform',
  as: `${getLink(data)}/free-platform`
});

export const getHrefAndAsOfSaved = (goal) => ({
  href: `${getLinkHref()}/saved`,
  as: `${getLink(goal)}/saved`
});

export const getCoursesHref = () => `${getTopicLinkHref()}/courses`;

export const getCoursesAsPath = (goalSlug, goalUID, topicUID) =>
  `${getTopicLink(
    { uid: topicUID },
    { slug: goalSlug, uid: goalUID }
  )}/courses`;

export const getTopologyName = {
  [topologyLevels.CATEGORY]: 'Category',
  [topologyLevels.GOAL]: 'Goal',
  [topologyLevels.GROUP]: 'Topic Group',
  [topologyLevels.TOPIC]: 'Topic',
  [topologyLevels.CONCEPT]: 'Concept',
  [topologyLevels.SUBCONCEPT]: 'Subconcept'
};

const topology = {
  Topology: topologyModal,
  getEntityData,
  getLink,
  getLinkHref,
  getSpecialClassesLink,
  getLinkPath,
  getTopicLink,
  getTopicListLink,
  getSpecialClassesAllLink,
  getHrefAndAsOfAFreeCourse,
  getTopicListHref,
  getSpecialClassesLinkHref,
  getTopicLinkHref,
  getHrefAndAsOfSaved,
  getTopologyName
};

export default topology;
