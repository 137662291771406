const paginateAction =
  (
    defaultUrl,
    apiFetch,
    paginatedKey,
    resultKey,
    resetWithoutEmpty = false,
    forceReset = false
  ) =>
  (nextPage) =>
  (dispatch, getState) => {
    if (forceReset) {
      return dispatch({ ...apiFetch(defaultUrl) });
    }

    let {
      next = defaultUrl,
      loaded,
      isFetching
    } = getState().paginator[paginatedKey]?.[resultKey] || {};
    if (resetWithoutEmpty) next = defaultUrl;
    if ((loaded && !nextPage) || isFetching) return null;
    return dispatch({ ...apiFetch(next) });
  };

export default paginateAction;
