import { feedblockTypes } from '@constants/index';

const isEducatorFeed = (feedBlockEntityType) =>
  feedBlockEntityType === 'Educator';

const isClassFeed = (feedBlockEntityType) => feedBlockEntityType === 'Course';

const isBatchFeed = (feedBlockEntityType) => feedBlockEntityType === 'Batch';

const isCompeteFeed = (feedBlockEntityType) =>
  feedBlockEntityType === 'compete_home_banner';

const isSubjectFeed = (feedBlockEntityType) =>
  feedBlockEntityType === 'Subjects';

const isContentStatsFeed = (feedBlockEntityType) =>
  feedBlockEntityType === 'content_stats';

const isLiveMentorshipFeed = (feedBlockEntityType) =>
  feedBlockEntityType === 'live_mentorship';

const isLessonFeed = (feedBlockEntityType) => feedBlockEntityType === 'Lessons';

const extraBlockInfoTopicIDsAndEntityTypes = [
  'Topology',
  'topology',
  'topics',
  'topic',
  'topic_groups',
  'group'
];
const isTopicFeed = (feedBlockEntityType) =>
  extraBlockInfoTopicIDsAndEntityTypes.includes(feedBlockEntityType);

const testSeriesKeys = ['TestSeries', 'Test_Series'];
const isTestSeriesFeed = (feedBlockEntityType) =>
  testSeriesKeys.includes(feedBlockEntityType);

const isGenericBanner = (feedBlockType) =>
  feedBlockType === feedblockTypes.GENERIC_BANNER;

const isPreActivationBlock = (feedBlockType) =>
  [
    feedblockTypes.EXT_SUBSCRIPTION_PREACTIVATION,
    feedblockTypes.EXT_SUBSCRIPTION_POSTACTIVATION
  ].includes(feedBlockType);

const coursesKeys = [
  'Course',
  'TestSeries',
  'Test_Series',
  'legends_on_unacademy',
  'banner',
  'programme',
  'test_series',
  'post',
  'special_class'
];
const isCourseFeedBlock = (feedBlockType) =>
  coursesKeys.includes(feedBlockType);

const educatorKeys = ['Educator', 'user'];
// Looks like a duplicate of isEducatorFeed
const isEducatorBlock = (feedBlockType) => educatorKeys.includes(feedBlockType);

const batchKeys = ['Batch', 'batch'];

// Looks like a duplicate of isBatchFeed
const isBatchBlock = (feedBlockType) => batchKeys.includes(feedBlockType);

const batchGroupKeys = ['batchGroup', 'Batchgroup'];

const isBatchGroupBlock = (feedBlockType) =>
  batchGroupKeys.includes(feedBlockType);

const bannerBlockKeys = ['carousel_feed'];
const isBannerBlock = (feedBlockType) =>
  bannerBlockKeys.includes(feedBlockType);

export const batchFeedOptions = [
  { label: 'All', value: 0 },
  { label: 'Ongoing', value: 1 },
  { label: 'Upcoming', value: 2 },
  { label: 'Completed', value: 3 }
];

export const getBatchFeedKey = (goalUID, batchesType) =>
  `batches_${goalUID}_${batchesType}`;

export const getBatchesGroupFeedKey = (goalUID, batchesType) =>
  `batchesGroup_${goalUID}_${batchesType}`;

export const getBlockInfoKey = (seeAllType, goalUID, type) =>
  `${seeAllType}-${goalUID}-${type}`;

export const getSeeAllFeedKey = (
  isDropdownRequired,
  seeAllType,
  goalUID,
  type,
  selectedOptionValue
) => {
  const blockKey = getBlockInfoKey(seeAllType, goalUID, type);
  return isDropdownRequired ? `${blockKey}-${selectedOptionValue}` : blockKey;
};

export const getTestSeriesFeedKey = (goalUID, testSeriesType) =>
  `testSeries_${goalUID}_${testSeriesType}`;

export {
  isClassFeed,
  isEducatorFeed,
  isContentStatsFeed,
  isBatchFeed,
  isSubjectFeed,
  isTopicFeed,
  isTestSeriesFeed,
  isGenericBanner,
  isPreActivationBlock,
  isCourseFeedBlock,
  isEducatorBlock,
  isBatchBlock,
  isBannerBlock,
  isBatchGroupBlock,
  isLiveMentorshipFeed,
  isLessonFeed,
  isCompeteFeed
};
