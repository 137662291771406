import dateHelper from '@utils/dates';
import textHelper from '@utils/textHelper';
import { courseStates, itemTypes, programmeTypes } from '@constants/index';
import { getLink as getItemLink } from './Item';

export const getInfoString = (course) => {
  const liveAt = course.liveAt || course.startsAt;
  if (!liveAt) return '';
  let infoString = '';
  if (course.state === courseStates.COMPLETED) {
    infoString = `${dateHelper.getDateShort(
      liveAt
    )} • ${dateHelper.getTimeFormatInShortFormWithoutSecs(
      course.lessonDuration
    )}`;
  } else if (course.state === courseStates.LIVE) {
    infoString = `Started on ${dateHelper.formatTime(liveAt)}`;
  }
  infoString = `Starts on ${dateHelper.getDateShort(
    liveAt
  )} • ${dateHelper.formatTime(liveAt)}`;
  return infoString;
};

export const isUpcoming = (date) => {
  if (!date) return false;
  const now = new Date();
  return new Date(date).getTime() > now.getTime();
};

export const getDefaultThumbnail = (course) => {
  // To not show plus thumbnail if it's a free course
  if (
    (course.for_plus || course.forPlus) !== false &&
    (course.programme_type || course.programmeType) ===
      programmeTypes.TESTSERIES
  ) {
    return 'https://static.uacdn.net/thumbnail/course/testseries-placeholder.png';
  }
  return null;
};

export const isOngoing = (startsAt, endsAt) => {
  if (!startsAt || !endsAt) return false;
  const now = new Date();
  return (
    new Date(startsAt).getTime() < now.getTime() &&
    new Date(endsAt).getTime() > now.getTime()
  );
};

export const isCompleted = (date) => {
  if (!date) return false;
  const now = new Date();
  return new Date(date).getTime() < now.getTime();
};

export const isSummitClass = (data = {}) => data.goal?.uid === 'DCGBT';

function courseModel(course) {
  const topicGroups = course.topic_groups || [course.topic_group];
  const data = {
    uid: course.uid,
    name: course.name,
    isPartOfBatch: course.is_part_of_batch,
    thumbnail:
      course.cover_photo_v1 ||
      course.thumbnail_v1 ||
      course.thumbnail ||
      course.cover_photo ||
      getDefaultThumbnail(course),
    newThumbnail: course.cover_photo_v1,
    description: course.description || null,
    permalink: course.permalink,
    relativeLink: course.relative_link,

    averageRating: course.avg_rating,
    slug: course.slug,
    isActive: course.is_active,
    totalReviews: course.total_reviews,
    totalRatings: course.total_ratings,
    forPlus: course.for_plus || course.for_subscription,
    forSubscription: course.for_subscription,
    livestreamUrl: course.livestream_url,
    totalTime: course.total_time,
    totalSessionTime: course.total_session_time,
    viewCount: course.view_count,
    savedCount: course.saved_count,
    previewVideo: course.intro_video,
    language:
      course?.language_display || course?.multiple_language_display?.[0],
    liveClassCount: course.sessions_count,
    isEnrolled: course.is_enrolled,
    isBookmarked: course.bookmarked,
    isSpecial: course.is_special,
    isIconic: course.is_iconic,
    enrollmentCount: course.enrollment_count,
    secondaryType: course.secondary_type,
    courseType: course.programme_type,
    isLive: course.is_live,
    state: course.state ?? course.status,
    liveClassesRemaining: course.sessions_remaining,
    itemCount: course.all_item_count || course.item_count,
    lessonsWatched: course.lessons_watched,
    lessonsCompleted: course.lessons_completed,
    startsAt: course.starts_at,
    endsAt: course.ends_at,
    isUpcoming: isUpcoming(course.starts_at),
    isOngoing: isOngoing(course.starts_at, course.ends_at),
    isCompleted: course.is_completed || isCompleted(course.ends_at),
    quizCount: course.all_item_count - course.sessions_count,
    channelDetails: course.channel,
    nextTrack: {
      item:
        course.next_track ||
        course.next_session ||
        course.next_class ||
        course.next_item
    },
    previousTrack: { item: course.previous_session },
    authors: course.authors
      ? course.authors.map((author) => ({ user: author }))
      : [{ user: course.author }],
    topicGroups: topicGroups
      ? topicGroups.map((topicGroup) => ({ topology: topicGroup }))
      : null,
    topic: course?.topic_groups?.[0]?.name,
    conceptTopology: course?.concept_topology?.name,
    coverPhoto:
      course.cover_photo_v1 ||
      course.cover_photo ||
      course.thumbnail ||
      getDefaultThumbnail(course),
    colorCode: course.color_code,
    goal: course.goal ? { topology: course.goal } : null,
    topologies: course.topologies
      ? course.topologies.map((topologyItem) => ({ topology: topologyItem }))
      : null,
    goalUid: course.goal_uid || course.goal?.uid || null,
    liveClasses: course.live_classes
      ? course.live_classes.map((itemData) => ({ item: itemData }))
      : null,
    tag: course.tag,
    unLiveUrl: course.unlive_url, // 'https://player.uacdn.net/liveweb/v1037/index.html',
    progress: course.progress,

    conceptLessonCount: course.lesson_count,
    ogImage: course.og_image || null,
    type: course.type || '', // in search page, we get type as `collection`
    voiceLanguages: course.voiceLanguages,
    contentSlideLanguages: course.contentSlideLanguages,
    batchGroup: course.batch_group || null,
    totalDPPCount: course?.total_practice_dpp_count || 0,
    queryID: course.queryID,
    requestID: course.requestID,
    index: course.index,
    isRecordedClassesOnlyCourse: !course.is_live_class_present
  };
  if (course.progress) {
    data.progress.nextLessonRelativeLink =
      course.progress.next_lesson_relative_link;
    data.progress.isCompleted = parseInt(course.progress.percentage, 2) === 100;
  }
  if (course.programme) {
    data.isRecordedClassesOnlyCourse = !course.programme.is_live_class_present;
  }
  data.infoString = getInfoString(data);
  return data;
}

function getEntityData(data) {
  return courseModel(data);
}

export const getTopicGroup = (data = {}) =>
  data.topicGroups ? data.topicGroups[0] : null;

const getAuthorsName = (authors) => {
  if (!authors) return '';
  const nameText = authors.slice(0, 3).reduce((initial, educator, index) => {
    if (index === 1) return initial + educator.name;
    if (index < 2 && authors.length === 1) return initial + educator.name;
    if (index < 2) return `${initial} ${educator.name}, `;
    return `${initial} and ${authors.length - 2} more`;
  }, '');
  return nameText;
};

export const getLinkPath = (data) => {
  // if course is special
  if (data.isSpecial || data.showAsLesson) {
    // if a course is special class and inside properties of nextTrack/nextSession uid and relativeLink is available then use class link instead of course link to save redirect from course to class
    const { nextTrack } = data;
    if (
      nextTrack &&
      (nextTrack?.uid ||
        (nextTrack?.properties?.uid && nextTrack?.properties?.relativeLink))
    ) {
      return '/class/[slug]/[uid]';
    }
    return '/course/[slug]/[courseUID]';
  }
  const { type, forPlus, courseType } = data;

  // lesson - Free course
  if (
    !forPlus &&
    data.progress &&
    !data.progress.isCompleted &&
    data.progress.nextLessonRelativeLink
  ) {
    return '/lesson/[lessonSlug]/[lessonUID]';
  }
  // checking whether the passed object is course or item. (item)
  if (type === itemTypes.POST) {
    // if item and plus item means a class
    if (forPlus) return '/class/[slug]/[uid]';
    return '/lesson/[lessonSlug]/[lessonUID]';
  }
  if (
    courseType === programmeTypes.TESTSERIES ||
    courseType === programmeTypes.HYBRID_TESTSERIES
  ) {
    return '/test-series/[slug]/[courseUID]/';
  }
  return '/course/[slug]/[courseUID]/';
};
export const getLink = (data) => {
  if (data.isSpecial || data.showAsLesson) return getItemLink(data);

  if (
    !data.forPlus &&
    data.progress &&
    !data.progress.isCompleted &&
    data.progress.nextLessonRelativeLink
  ) {
    return data.progress.nextLessonRelativeLink;
  }

  let { slug } = data;
  const { type, forPlus, courseType } = data;
  if (!slug) {
    const courseName = data.name?.replace(/\/|\\/g, ' ');
    slug = textHelper.getStringWordsJoinedWith(courseName, '-');
  }
  if (
    courseType === programmeTypes.TESTSERIES ||
    courseType === programmeTypes.HYBRID_TESTSERIES
  ) {
    return `/test-series/${slug}/${data.uid}`;
  }
  return `/${
    type === itemTypes.POST ? (forPlus && 'class') || 'lesson' : 'course'
  }/${slug}/${data.uid}`;
};

const course = {
  Course: courseModel,
  getEntityData,
  getAuthorsName,
  getTopicGroup,
  getLink,
  getLinkPath,
  isSummitClass
};

export default course;
