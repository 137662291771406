/* eslint-disable camelcase */
function combat(combatData = {}) {
  const data = {
    uid: combatData.uid,
    author: combatData.author,
    authorsCount: combatData.authors_count,
    description: combatData.description,
    forPlus: combatData.for_plus,
    isActive: combatData.isActive,
    name: combatData.name,
    title: combatData.name,
    slug: combatData.slug,
    programme: combatData.programme,
    isEnrolled: combatData.programme.is_enrolled,
    opensAt: combatData.programme.opens_at,
    startsAt: combatData.programme.starts_at,
    isSpecial: combatData.programme.is_special,
    goal: combatData.programme.goal,
    user: combatData.author,
    queryID: combatData.queryID,
    requestID: combatData.requestID,
    index: combatData.index
  };

  return data;
}

function getEntityData(data) {
  return combat(data);
}

const courseTemplate = {
  Combat: combat,
  getEntityData
};

export default courseTemplate;
