function batchGroupModel(batchGroup) {
  const topicGroups = batchGroup.topic_groups || batchGroup.topicGroups;
  const extraDetails =
    batchGroup.extra_details || batchGroup.extraDetails || {};

  return {
    batchUID: batchGroup.batch_uid || batchGroup.batchUid,
    uid: batchGroup.uid,
    subscriptionUID: batchGroup.subscription_uid || batchGroup.subscriptionUid,
    channel: batchGroup.channel,
    name: batchGroup.name,
    slug: batchGroup.slug,
    description: batchGroup.description,
    price: batchGroup.price,
    isEnrolled: batchGroup.is_enrolled || !!batchGroup.isEnrolled,
    duration: batchGroup.duration,
    timeType: batchGroup.time_type || batchGroup.timeType,
    thumbnail: batchGroup.cover_photo || batchGroup.coverPhoto,
    state: batchGroup.state,
    startsAt: batchGroup.starts_at || batchGroup.startsAt,
    completedAt: batchGroup.completed_at || batchGroup.completedAt,
    endsAt: batchGroup.completed_at || batchGroup.completedAt,
    preview: batchGroup.intro_video || batchGroup.introVideo,
    testSeries: batchGroup.test_series_count || batchGroup.testSeriesCount,
    languages: batchGroup.languages,
    allTopicsCovered:
      batchGroup.all_topics_covered || batchGroup.allTopicsCovered,
    enrollmentEndDate:
      batchGroup.enrollment_end_date || batchGroup.enrollmentEndDate,
    enrollmentStartDate:
      batchGroup.enrollment_start_date || batchGroup.enrollmentStartDate,
    authors: batchGroup.authors?.map((author) => author),
    topicGroups: topicGroups
      ? topicGroups.map((topicGroup) => {
          return { topology: topicGroup };
        })
      : null,
    goal: batchGroup.goal || {},
    quizzesCount: batchGroup.quizzes_count || batchGroup.quizzesCount,
    lessonsCount: batchGroup.lessons_count || batchGroup.lessonsCount,
    trialClasses: batchGroup.trial_info || batchGroup.trialInfo || {},
    startedDate: batchGroup.started_at || batchGroup.startedAt,
    groupType: batchGroup.group_type || batchGroup.groupType,
    partnerLog: batchGroup.partner_logo_url || batchGroup.partnerLogoUrl,
    isActive: batchGroup.is_active || batchGroup.isActive,
    extraDetails,
    batchExpiryDate: batchGroup?.access_end_date || batchGroup.accessEndDate,
    permalink: batchGroup.permalink,
    isDiscountAvailable:
      batchGroup.is_discount_available || batchGroup.isDiscountAvailable,
    discountEndDate: batchGroup.discount_end_date || batchGroup.discountEndDate,
    listPrice: batchGroup.list_price || batchGroup.listPrice,
    promoText: batchGroup.promo_text || batchGroup.promoText,
    isNotified: batchGroup.is_notified || batchGroup.isNotified,
    batchGroupMetaInfo:
      batchGroup.batch_group_meta_info || batchGroup.batchGroupMetaInfo,
    classType: batchGroup.class_type || batchGroup.classType,
    stateMetaInfo: batchGroup.state_meta_info || batchGroup.stateMetaInfo,
    feedbackData: batchGroup.feedback_data || batchGroup.feedbackData,
    metaInfo: batchGroup.meta_info || batchGroup.metaInfo
  };
}
function getEntityData(data) {
  return batchGroupModel(data);
}

export const getLink = (batchGroup) =>
  `/batch-group/${batchGroup.slug}/${batchGroup.uid}`;

export const LinkPath = '/batch-group/[slug]/[batchGroupUID]';

const batchGroup = {
  BatchGroup: batchGroupModel,
  getEntityData
};

export default batchGroup;
